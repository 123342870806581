.menu-item,
.menu-open-button {
  border-radius: 100%;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  position: absolute;
  top: 20px;
  text-align: center;
  line-height: 100px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  user-select: none;
}

.menu-item--dark,
.menu-open-button--dark {
  background: #1c1c1d;
}

.menu-item--light,
.menu-open-button--light {
  background: white;
}

.menu-open {
  display: none;
}
.hamburger {
  width: 25px;
  height: 3px;
  background: #e91e63;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;
  transition: transform 200ms;
}
.hamburger-1 {
  transform: translate3d(0, -8px, 0);
}
.hamburger-2 {
  transform: translate3d(0, 0, 0);
}
.hamburger-3 {
  transform: translate3d(0, 8px, 0);
}
.menu-open:checked + .menu-open-button .hamburger-1 {
  transform: translate3d(0, 0, 0) rotate(45deg);
}
.menu-open:checked + .menu-open-button .hamburger-2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}
.menu-open:checked + .menu-open-button .hamburger-3 {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}
.menu {
  position: absolute;
  left: 5%;
  margin-left: -80px;
  padding-top: 20px;
  padding-left: 80px;
  width: 650px;
  height: 150px;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
}
.menu-item:hover {
  background: transparent;
}
.menu-item:nth-child(3) {
  transition-duration: 180ms;
}
.menu-item:nth-child(4) {
  transition-duration: 180ms;
}
.menu-item:nth-child(5) {
  transition-duration: 180ms;
}
.menu-item:nth-child(6) {
  transition-duration: 180ms;
}
.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}
.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}
.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}
.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 190ms;
  transform: translate3d(110px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 290ms;
  transform: translate3d(220px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 390ms;
  transform: translate3d(330px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(6) {
  transition-duration: 490ms;
  transform: translate3d(440px, 0, 0);
}

.githubButton {
  background: transparent no-repeat center;
  content: url("../../assets/github.svg");
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  border: none;
  cursor: pointer;
}

.trelloButton {
  background: transparent no-repeat center;
  content: url("../../assets/trello.svg");
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  border: none;
  cursor: pointer;
}
