.button-submit {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  background: #e8e8e8;
  border: 0;
  cursor: pointer;
  border-bottom: 3px solid #c9c9c9;
  border-radius: 3px;
}

.button-submit::selection {
  background: transparent;
}

.button-submit--blue {
  color: black;
  background: #e8e8e8;
  border-color: #75f8e2;
}

.button-submit--blue:hover {
  z-index: 1;
  background: #6ad85c;
  border-color: #75f8e2;
  border-radius: 2px;
  outline: none;
}

.button-submit-small {
  font-size: 16px;
  padding: 10px 16px;
}
.button-submit-medium {
  font-size: 18px;
  padding: 11px 20px;
}
.button-submit-large {
  font-size: 20px;
  padding: 12px 24px;
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
