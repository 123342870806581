:root {
  --shadow: transparent 0px 0px 0px 2px inset, transparent 10px -10px 0px -3px,
    rgb(31, 193, 27) 10px -10px, transparent 20px -20px 0px -3px,
    rgb(255, 217, 19) 20px -20px, transparent 30px -30px 0px -3px,
    rgb(255, 156, 85) 30px -30px, transparent 40px -40px 0px -3px,
    rgb(255, 85, 85) 40px -40px;
}

.pulse {
  --color: #8ed622;
  --hover: #8ed622;
}

.pulse:hover,
.pulse:focus {
  background: var(--hover);
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

#draggableButton {
  color: #fff;
  transition: 0.25s;
  user-select: none;
  cursor: pointer;
  background-color: var(--hover);
  border: 2px solid transparent;
  font: inherit;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  transition: cursor 0.3s, background-color 0.3s;
  z-index: 6;
}

#draggableButton.clickable {
  border-color: var(--hover);
  color: #fff;
}

#draggableButton.clickable.light {
  color: #fff;
}

#draggableButton.clickable.dark {
  color: #282c34;
}

.cornerShine {
  width: 183px;
  height: 40px;
  position: absolute;
  bottom: 30px;
  left: 40px;
  background-color: transparent;
  animation: pulse 0.6s;
  box-shadow: var(--shadow);
}
