.title {
  font-weight: bold;
  line-height: 30px;
  vertical-align: top;
  display: inline-block;
}

.title--dark {
  color: white;
}

.title--light {
  color: #282c34;
}

.title--small {
  font-size: 18px;
  padding: 10px;
}
.title--medium {
  font-size: 25px;
  padding: 15px;
}
.title--large {
  font-size: 30px;
  padding: 35px;
}
