.container {
  padding-top: 90px;
  text-align: center;
  background: transparent;
}

.section-container {
  line-height: 34px;
  margin: auto;
  max-width: 800px;
}

.settings-button {
  position: absolute;
  top: 6%;
  right: 5%;
  margin-top: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

.settings-icon {
  background: transparent no-repeat center;
  content: url("../../assets/settings.svg");
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  border: none;
  cursor: pointer;
  transition: transform 200ms;
}

.settings-icon:hover {
  transition: transform 200ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
}
