.quiz {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  background: transparent;
  min-height: 200px;
  height: min-content;
  padding: 20px;
  font-size: 19px;
  font-weight: 600;
  margin-top: 3%;
  border: 2px solid;
}

.quiz--light {
  color: black;
  border-color: #282c34;
}

.quiz--dark {
  color: white;
  border-color: white;
}

.question {
  width: 100%;
  right: 80%;
}
.questionText {
  font-size: 19px;
  margin: 20px 0;
  margin-bottom: 12px;
}

.answers {
  margin-bottom: 20px;
}

.answer {
  position: relative;
  padding: 4px;
  text-align: center;
  background-color: transparent;
  margin-bottom: 7px;
  cursor: pointer;
  line-height: 35px;
  font-size: 18px;
  min-width: 80px;
  min-height: 35px;
  border: 2px solid #75f8e2;
}

.answer:hover {
  background-color: #75f8e2;
}

.answer.selected {
  background: #75f8e2;
}

.answer.correct {
  background: #6ad85c;
  font-weight: bold;
}

.answer.incorrect {
  background: #fcb0b0;
  font-weight: bold;
}

.answer-text {
  position: relative;
  z-index: 2;
}

.progressBar {
  width: 100%;
  background: transparent;
  height: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #75f8e2;
  font-size: 20px;
}

.progressBar .inner {
  background: #6ad85c;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  transition: ease all 0.5s;
}

.progressBar .text {
  font-size: 11px;
  position: absolute;
  z-index: 3;
}
