.form-container {
  display: flex;
  background: transparent;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 0;
  font-size: 19px;
  font-weight: 600;
  margin-top: 2%;
}

.success-message {
  color: #75f8e2;
  background: transparent;
  width: 90%;
  padding: 8px;
  border: 1px solid;
  margin-top: 13px;
  font-size: 19px;
  font-weight: 600;
}

.form-label {
  font-size: 19px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.form-label--light {
  color: black;
}

.form-label--dark {
  color: white;
}

.form-input {
  padding: 10px;
  border: 1px solid #282c34;
}
.form-input:focus {
  z-index: 1;
  border-color: #282c34;
  background-color: white;
  box-shadow: 5px 10px 15px #61dafb;
  outline: none;
  transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

  transition-property: color, background, width, height, margin, padding, border,
    box-shadow;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.form-input-error {
  padding: 10px;
  border: 1px solid;
  border-color: red;
  transition: 0.3s ease-in-out;
}

.form-input-error:focus {
  z-index: 1;
  border-color: transparent;
  background-color: white;
  box-shadow: 5px 10px 15px red;
  outline: none;
  transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

  transition-property: color, background, width, height, margin, padding, border,
    box-shadow;
}

.form-input-error::placeholder {
  color: red;
  font-family: "Audiowide", sans-serif;
}

.form-input::placeholder {
  font-family: "Audiowide", sans-serif;
}

.error {
  color: red;
  font-size: 14px;
}
