.app-wrapper {
  border-bottom: 1px solid #0000001a;
  background-color: #1e1e1e;
  padding: 3px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  width: 100%;
  position: relative;
}

.app-wrapper--dark {
  background: linear-gradient(black, #2a2a2a);
  color: white;
  box-shadow: 0 0 20px #2a2a2a, 10px 0 20px #2a2a2a;
}
.app-wrapper--light {
  background: linear-gradient(#cecece, white);
  color: #1e1e1e;
  box-shadow: 0 0 20px #cecece, 10px 0 20px #cecece;
}

.app-h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
}
