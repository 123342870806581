.gift-button {
  font-weight: 700;
  font-family: "Audiowide", sans-serif;
  border: none;
  max-width: 300px;
  max-height: 300px;
  border-radius: 6px;
  cursor: pointer;
  padding: 48px;
  transition: 0.5s;
  box-shadow: 0 0 0 50px rgba(255, 0, 0, 0);
}

.gift-button::selection {
  user-select: none;
}

.gift-logo {
  pointer-events: none;
  user-select: none;
}

.gift-button--primary {
  color: #ff0055;
  background: transparent;
}

.gift-button--primary:hover {
  box-shadow: 0 0 0 0px #ff0055;
}

.gift-button--secondary {
  color: #61dafb;
  background: transparent;
}

.gift-button--secondary:hover {
  box-shadow: 0 0 0 0px #61dafb;
}

.gift-button--small {
  font-size: 11px;
  padding: 12px 24px;
}
.gift-button--medium {
  font-size: 12px;
  padding: 13px 28px;
}
.gift-button--large {
  font-size: 13px;
  padding: 14px 32px;
}
