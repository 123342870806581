.skip-position {
  position: absolute;
  top: 6%;
  right: 10%;
  margin-top: 0;
}

.skip-button {
  background: transparent no-repeat center;
  content: url("../../assets/skip.svg");
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  border: none;
  cursor: pointer;
}

.skip-button:hover {
  transition: transform 200ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
}
