#darkMode {
  background: transparent url("../../assets/reactHeart.svg") no-repeat center;
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  /* filter: grayscale(100%); */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
}

#noStyle {
  position: relative;
  width: 2.3em;
  height: 2.3em;
  background: #f9f8f8;
  border: 1px solid #ddd;
  border-radius: 50%;
  left: 0em;
  transition: left 0.3s ease-out;
}

#darkMode:hover,
#darkMode:focus {
  filter: none;
  background-color: transparent;
  cursor: pointer;
}

#darkMode.clicked {
  filter: none !important;
  background-color: transparent;
}
