.modal__backdrop {
  background: #000000a6;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4;
}

.modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 50px auto;
  padding: 20px;
  width: 460px;
}
.modal__container--light {
  background: white;
  color: black;
}

.modal__container--dark {
  background: #282c34;
  color: white;
}
