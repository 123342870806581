:root {
  --black: #282c34;
  --white: white;
  --react-blue: #61dafb;
  --react-darker-blue: #2bd1ff;
  --react-darkest-blue: #0097c1;
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Audiowide", sans-serif;
  background: var(--background-color);
  color: var(--text-color);
}
body.dark {
  --background-color: var(--black);
  --text-color: var(--white);
}

body.light {
  --background-color: var(--white);
  --text-color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
