.mobileViewContainer {
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: center;
}

.containerDark {
  color: white;
}

.containerLight {
  color: #282c34;
}
