.unlock-button {
  background: transparent;
  color: transparent;
  border: none;
  border-radius: 9px;
  padding: 3px;
}

.unlock-button::selection {
  background: transparent;
}
