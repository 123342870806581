.toggle-container {
  margin: 10px 0;
}

.toggle-label {
  font-size: medium;
  font-weight: bold;
}

.toggle-text {
  font-size: small;
  font-weight: bold;
}

/* The switch - the box around the slider */
.toggle-switch {
  margin: 5px 0;
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

/* Hide default HTML checkbox */
.toggle-switch .toggle-switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-switch-input:checked + .slider {
  background-color: #e91e63;
}

.toggle-switch-input:focus + .slider {
  box-shadow: 0 0 1px #e91e63;
}

.toggle-switch-input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

/* Rounded sliders */
/* .slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} */
