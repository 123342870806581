.placeholder-container {
  display: block;
  line-height: 34px;
  margin: auto;
  max-width: 800px;
}

.placeholder-container--small {
  min-height: 150px;
}

.placeholder-container--medium {
  min-height: 175px;
}

.placeholder-container--large {
  min-height: 200px;
}

.placeholder-container-dark {
  background-color: #282c34;
  color: white;
}

.placeholder-container-light {
  background-color: white;
  color: #282c34;
}
